import AOS from "aos";

(function () {
    AOS.init({
        once: true,
    });

    document.addEventListener("DOMContentLoaded", () => {
        AOS.refresh();
    });
})();
