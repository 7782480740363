import YouTubePlayer from 'youtube-player';
import VimeoPlayer from '@vimeo/player';

class YouTubeAutoPause {
    constructor(element) {
        this.element = element;
        this.player = YouTubePlayer(element, {
            videoId: element.getAttribute("data-video-id"),
        });
        this.createObserver();
    }

    createObserver() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        this.pauseVideoIfPlaying();
                    }
                });
            },
            { threshold: 0.8 }
        );
        observer.observe(this.element);
    }

    async pauseVideoIfPlaying() {
        const currentState = await this.player.getPlayerState();
        if (currentState === 1) {
            this.player.pauseVideo();
        }
    }
}

class HTML5VideoAutoPause {
    constructor(element) {
        this.element = element;
        this.createObserver();
    }

    createObserver() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        this.pauseVideoIfPlaying();
                    }
                });
            },
            { threshold: 0.8 }
        );
        observer.observe(this.element);
    }

    pauseVideoIfPlaying() {
        if (!this.element.paused) {
            this.element.pause();
        }
    }
}

class VimeoAutoPause {
    constructor(element) {
        this.element = element;
        this.player = new VimeoPlayer(element);
        this.createObserver();
    }

    createObserver() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        this.pauseVideoIfPlaying();
                    }
                });
            },
            { threshold: 0.8 }
        );
        observer.observe(this.element);
    }

    async pauseVideoIfPlaying() {
        const currentState = await this.player.getPaused();
        if (!currentState) {
            this.player.pause();
        }
    }
}

class VideoAutoPause {
    constructor(youtubeSelector, html5Selector, vimeoSelector) {
        this.initYouTubeVideos(youtubeSelector);
        this.initHTML5Videos(html5Selector);
        this.initVimeoVideos(vimeoSelector);
    }

    initYouTubeVideos(selector) {
        const youtubeVideos = document.querySelectorAll(selector);
        youtubeVideos.forEach((element) => new YouTubeAutoPause(element));
    }

    initHTML5Videos(selector) {
        const html5Videos = document.querySelectorAll(selector);
        html5Videos.forEach((element) => new HTML5VideoAutoPause(element));
    }

    initVimeoVideos(selector) {
        const vimeoVideos = document.querySelectorAll(selector);
        vimeoVideos.forEach((element) => new VimeoAutoPause(element));
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new VideoAutoPause(".yt_player", "video", ".vimeo_player");
});