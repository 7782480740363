class hamburgerMenu {
    constructor(iconToggle, menuContainer) {
        this.iconToggle = iconToggle;           // menu icon
        this.menuContainer = menuContainer;     // menu nav container
        this.expanded = iconToggle?.getAttribute('aria-expanded') === 'true';  // check if menu is expanded

        if (this.iconToggle && this.menuContainer) {  // check if contains elements
            this.clickEvent();
            this.keyboardEvent();
        } else {
            return;
        }
    }

    clickEvent() {
        this.iconToggle.addEventListener("click", () => {
            this.menuToggle();
            this.expanded = !this.expanded;
            this.iconToggle.setAttribute('aria-expanded', this.expanded);
        });
    }

    keyboardEvent() {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.removeMenuSectionStates();
                this.iconToggle.classList.remove("hamburger-menu--active");
                this.menuContainer.classList.remove("is-active");
            }
        });
    }

    menuToggle() {
        this.removeMenuSectionStates();
        this.iconToggle.classList.toggle("hamburger-menu--active");
        this.menuContainer.classList.toggle("is-active");

        this.menuSectionListener();
    }

    removeMenuSectionStates() {
        this.menuContainer.querySelectorAll('.menu--submenu').forEach((section) => {
            section.classList.remove('is-active');
        });
    }

    menuSectionListener() {
        this.menuContainer.addEventListener('click', this.sectionToggle);
    }

    sectionToggle(e) {
        if (e.target.classList.contains('icon--dropdown')) {
            if (e.target.parentNode.querySelector('[data-menu-section]')) {
                e.target.parentNode.querySelector('[data-menu-section]').classList.add('is-active');
            }
        }

        if (e.target.classList.contains('btn-return') || e.target.parentNode.classList.contains('btn-return')) {
            e.target.closest('[data-menu-section]').classList.remove('is-active');
        }
    }
}

new hamburgerMenu(document.querySelector('[data-menu-icon]'), document.querySelector('[data-menu-container]'));
