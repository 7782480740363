const splash = document.querySelector('.splash');

function loadingSplashPage() {
    if (!showSplashPageOnce()) {
        splash.classList.remove('is-hidden');
        window.onload = () => {
            setTimeout(removeLoadingSplashPage, 2000);
        };
    }
}

function showSplashPageOnce() {
    const isVisited = localStorage.getItem('hasSplashed');

    if (!isVisited) {
        localStorage.setItem('hasSplashed', 'true');
        return false;
    }

    return true;
}

function removeLoadingSplashPage() {
    if (!splash) {
        return;
    }

    splash.classList.add('splash--remove');
    splash.addEventListener("transitionend", () => {
        splash.classList.add('is-hidden');
    });
}

loadingSplashPage();