import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, FreeMode } from 'swiper/modules';
import { swiperSizes, swiperOptions } from "../config/swiperConfig";
import { breakpoints } from "../core/constants";

function createSwiperInstance(container, config = {}) {
    const slideSize = container.getAttribute('slide-width');
    const showControls = container.getAttribute('show-controls');
    const showPagination = container.getAttribute('show-pagination');

    config = {
        ...config,
        allowTouchMove: showControls === 'true' ? false : true,
        grabCursor: showControls === 'true' ? false : true,
        breakpoints: {
            [`${breakpoints.lg}`]: {
                slidesPerView: swiperSizes[slideSize],
            }
        }
    };

    return new Swiper(container, {
        ...{
            modules: [
                Navigation,
                Pagination,
                Autoplay,
                FreeMode,
            ],
        },
        ...swiperOptions,

        ...{
            // Navigation arrows
            navigation: {
                nextEl: container.closest(".pane").querySelector(".swiper__button--next"),
                prevEl: container.closest(".pane").querySelector(".swiper__button--prev"),
                disabledClass: 'swiper__button--disabled'
            },
            pagination: showPagination == 'true' ? {
                el: '.swiper__pagination',
                type: 'bullets',
                clickable: true,
                bulletClass: 'swiper__bullet',
                bulletActiveClass: 'is-active'
            } : false,
        },
        ...config,
    });
}

const swipers = [...document.querySelectorAll('.swiper:not(.swiper--logo)')];
swipers.forEach(swiper => {
    let config = {};
    const showControls = swiper.getAttribute('show-controls');
    
    if (swiper.classList.contains('swiper--highlighted-content')) {
        config = {
            enabled: true,
            allowTouchMove: showControls === 'true' ? false : true,
            grabCursor: showControls === 'true' ? false : true,
        }

        config.breakpoints = {
            [`${breakpoints.xs}`]: {
                enabled: true,
            },
            [`${breakpoints.lg}`]: {
                enabled: false,
                allowTouchMove: false,
                grabCursor: false,
            }
        }        
    }

    createSwiperInstance(swiper, config);
});

const logoSwipers = [...document.querySelectorAll('.swiper--logo')];

logoSwipers.forEach(swiper => {
    const config = {
        slidesPerView: 2.5,
        centeredSlides: true,

        breakpoints: {
            [`${breakpoints.lg}`]: {
                enabled: false,
                grabCursor: false,
            }
        }
    };
    createSwiperInstance(swiper, config);
})
