import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class BlockquoteAnimator {
    // Constructor accepteert een selectorparameter, standaard zoekt deze naar blockquotes
    constructor(selector = 'body:not(.wp-admin) blockquote') {
        this.blockquotes = [...document.querySelectorAll(selector)];
    }

    // Verwijder ongewenste HTML-tags
    stripTags(html, ...allowedTags) {
        const regex = new RegExp(`</?((?!${allowedTags.join('|')})\\w+)\\b[^>]*>`, 'gi');
        return html.replace(regex, '');
    }

    // Methode om de blockquotes te animeren
    animateBlockquotes() {
        if (this.blockquotes.length) {
            this.blockquotes.forEach(blockquote => {
                // Verwijder ongewenste tags
                blockquote.innerHTML = this.stripTags(blockquote.innerHTML, "b", "strong", "em", "span");

                // Maak een GSAP-timeline voor de animatie
                const blockTl = gsap.timeline({
                    scrollTrigger: {
                        start: "top center",
                        end: "100% center",
                        scrub: true,
                        trigger: blockquote,
                        markers: false,
                    }
                });

                // Voeg de animatie toe
                blockTl.to(blockquote, {
                    backgroundSize: "100% 100%",
                });
            });
        }
    }
}

// Initialisatie van de klasse en het starten van de animatie
document.addEventListener('DOMContentLoaded', () => {
    const animator = new BlockquoteAnimator();
    animator.animateBlockquotes();
});

class ButtonAnimationObserver {
    constructor(buttons) {
        this.buttons = document.querySelectorAll(buttons);
        this.observeButtons();
    }

    observeButtons() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('button-pulse');
                } else {
                    entry.target.classList.remove('button-pulse');
                }
            });
        }, {
            threshold: 0.5
        });

        this.buttons.forEach(button => observer.observe(button));
    }
}

new ButtonAnimationObserver(`[data-button-animation="pulse"]`);