class Header {
    constructor(header) {
        if (!header) return;

        this.header = header;
        this.pixel = document.querySelector('#pixel-to-watch');

        this.init();
    }

    init() {
        if (!this.pixel) return;

        const myObserver = new IntersectionObserver((elements) => {
            if (elements[0].boundingClientRect.y < 0) {
                this.header.classList.add("is-scrolled");
            } else {
                this.header.classList.remove("is-scrolled");
            }
        });

        myObserver.observe(this.pixel);
    }
}

new Header(document.querySelector('.main-header'));
