import Macy from "macy";
import LazyLoad from "vanilla-lazyload";

new LazyLoad({});

function masonry(container) {
    let masonryContainer = document.querySelector(container);
    if (!masonryContainer) {
        return;
    }

    Macy({
        container: container,
        trueOrder: false,
        waitForImages: false,
        margin: 20,
        columns: 1,
        mobileFirst: true,
        breakAt: {
            768: {
                columns: 3,
            },
        },
    });
}

masonry(".masonry");
