class EditorManager {
    constructor(selector) {
        this.editorElements = document.querySelectorAll(selector);
        this.mediaQuery = window.matchMedia('(max-width: 920px)');
        this.initialize = this.initialize.bind(this);

        this.mediaQuery.addEventListener('change', this.initialize);
        window.addEventListener('DOMContentLoaded', this.initialize);
    }

    initialize() {
        if (this.mediaQuery.matches) {
            this.removeTagNameFromEditor('br');
        }
    }

    removeTagNameFromEditor(charToRemove) {
        this.editorElements.forEach(editor => this.recurse(editor, charToRemove));
    }

    removeCharacter(char, el) {
        el.querySelectorAll(char).forEach(charElement => charElement.remove());
    }

    recurse(el, charToRemove) {
        const editorChildren = Array.from(el.children).filter(child => child.tagName !== "P");

        editorChildren.forEach(child => {
            this.removeCharacter(charToRemove, child);
            this.recurse(child, charToRemove);
        });
    }
}

new EditorManager('.editor');